import {i18n} from '@/helpers/langs/i18n'

export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cil-speedometer'
      },
      // {
      //   _name: 'CSidebarNavTitle',
      //   _children: ['Theme']
      // },
      {
        _name: 'CSidebarNavItem',
        name: i18n.t('myClients'),
        to: '/users',
        icon: 'cil-user'
      },
      {
        _name: 'CSidebarNavItem',
        name: i18n.t('myEvaluations'),
        to: '/evaluations',
        icon: 'cil-file'
      },
      {
        _name: 'CSidebarNavItem',
        name: i18n.t('myNetwork'),
        to: '/networkusers',
        icon: 'cil-people'
      }
    ]
  }
]