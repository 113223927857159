<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <CIcon name="logo" height="48" alt="Logo"/>
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard">
          Dashboard
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/users" exact>
          Users
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink>
          Settings
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav class="mr-4">


			<CDropdown
				inNav
				class="c-header-nav-items"
				placement="bottom-end"
				add-menu-classes="pt-0"
			>
				<template #toggler>
          <CIcon name="cil-language"/>
				</template>
				<CDropdownItem @click="changeLocale('es')">
					{{ $t('spanish') }}
				</CDropdownItem>
				<CDropdownItem @click="changeLocale('en')">
					{{ $t('english') }}
				</CDropdownItem>
				<CDropdownItem @click="changeLocale('pt')">
					{{ $t('portugues') }}
				</CDropdownItem>
			</CDropdown>


      <TheHeaderDropdownAccnt/>
    </CHeaderNav>
    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0"/>
    </CSubheader>
  </CHeader>
</template>

<script>
import {i18n} from '@/helpers/langs/i18n'
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt
  },
	methods:{
		changeLocale(locale){
			i18n.locale = locale
			localStorage.setItem('lang', locale)
		}
	}
}
</script>
